.product-list-container {
  padding: 20px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
}

.product-block {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  text-align: center;
}

.product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.selected-products-container {
  padding: 20px;
}

.selected-products-list {
  list-style: none;
  padding: 0;
}

.selected-product-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
}

.selected-product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.select-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}
.ReactModal__Content {
	inset:0!important;
}

 .search-input-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff; /* Adjust as needed */
    z-index: 1000; /* Ensure it's above other content */
    padding: 10px; /* Adjust as needed */
  }

  .container {
    margin-top: 50px; /* Add margin to accommodate the fixed search input */
  }